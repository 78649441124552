<template>
    <div class="">
        <ATable :url="tableUrl" :columns="columns" :searchFormItems="searchFormItems" @operate-button-call-back="OperateButtonCallBack" ref="table">
            <!-- <el-button type="primary" @click="Editor()">新增</el-button> -->
        </ATable>
    </div>
</template>


<script>
import ATable from '@/components/table/index'

import { frontList, EmployeeEdit } from '@/api/user'
import { inject, provide, ref } from 'vue'

export default {
    components: {ATable},
    setup () {
        const tableUrl = ref(frontList)
        const elplus = inject('elplus')

        const columns = ref([
            {prop: 'name', label: '用户名'},
            {prop: 'nickname', label: '游戏昵称'},
            {prop: 'fish_count', label: '小鱼干数量'},
            {prop: 'financial_count', label: '财商币数量'},
            {prop: 'is_out', label: '是否外出', render: text => text == 1 ? '是' : '否'},
            {prop: 'out_num', label: '外出次数'},
            {prop: 'question_num', label: '答题次数'},
            {prop: 'level', label: '等级'},
            {prop: 'exp', label: '经验'},
            {prop: 'status', label: '是否不可见', render: text => text == 1 ? '可见' : '不可见'},
            {prop: 'special_status', label: '特殊状态'},
            {prop: 'operate', label: '操作', type: 'operate', width: 120, button: [
                // {label: '编辑', dispose: 'Editor'},
                // {label: '修改密码', dispose: 'EditorPass'},
                {label: '禁用', dispose: 'Forbidden'},
            ]},
        ])

        const searchFormItems = ref([
            {label: '用户名', prop: 'name', type: 'input'},
        ])

        let editorForm = ref({})
        let employeeVisible = ref(false)
        let table = ref(null)
        provide('employeeVisible', employeeVisible)
        provide('editorForm', editorForm)

        const methods = {
            Editor (row = {}) {
                if (row.id) {
                    editorForm.value = {name: row.name, pass: row.pass, phone: row.phone, id: row.id}
                } else {
                    editorForm.value = {name: '', pass: '', phone: ''}
                }
                employeeVisible.value = !employeeVisible.value
            },
            OperateButtonCallBack ({row, fun}) {
                methods[fun](row)
            },
            Forbidden (row) {
                elplus.confirm(`确定${row.status == 1 ? '禁用' : '启用'}该用户吗？`).then(() => {
                    let loading = elplus.loading()
                    EmployeeEdit({id: row.id, status: row.status == 1 ? 2 : 1}).then(res => {
                        loading.close()
                        if (!res.ret) methods.EmployeeEditSubmit()
                    })
                })
            },
            EditorPass (row) {
                elplus.prompt('请输入密码', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputType: 'password',
                    inputErrorMessage: '请输入新密码',
                    inputValidator (value) {
                        return !!value
                    },
                }).then(({ value }) => {
                    let loading = elplus.loading()
                    EmployeeEdit({id: row.id, pass: value}).then(res => {
                        loading.close()
                        if (!res.ret) methods.EmployeeEditSubmit()
                    })
                })
            },
            EmployeeEditSubmit () {
                employeeVisible.value = false
                table.value.SearchSubmit()
            }
        }


        return {
            table,
            columns,
            tableUrl,
            searchFormItems,
            ...methods
        }
    }
}
</script>

<style lang="scss" scoped>

</style>